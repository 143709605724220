import React from 'react'
import { ThemeContextProvider } from "./src/theme-context"
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo-client';

export const wrapRootElement = ({ element }) => (
  <ThemeContextProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </ThemeContextProvider>
);
