import ApolloClient from "apollo-boost"
import { PrismicLink } from 'apollo-link-prismic';
import { GITHUB_TOKEN } from './constants'
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri: "https://api.github.com/graphql",
  fetch,
  headers: {
    Authorization: `bearer ${GITHUB_TOKEN}`,
  },
});